'use client';

/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  SandpackCodeEditor,
  SandpackLayout,
  SandpackProvider,
  SandpackThemeProvider,
  useSandpack,
} from '@codesandbox/sandpack-react';

// @ts-expect-error - No types
import type { CodeMirrorRef } from '@codesandbox/sandpack-react/components/CodeEditor/CodeMirror';
import { githubLight } from '@codesandbox/sandpack-themes';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useEffectOnce, useMedia } from 'react-use';
import { useIsClient } from 'usehooks-ts';
import type { z } from 'zod';
import { SkeletonButton } from '~/components/atoms/skeleton/SkeletonButton';
import { Typo } from '~/components/atoms/typography/Typo';
import { Badge } from '~/components/catalyst/badge-catalyst';
import { SplitPane } from '~/components/lib/splite-pane/SplitPane';
import { tailwindMediaQuery } from '~/features/media-query/tailwind/media-query';
import { useTheme } from '~/features/theme/ThemeProvider';
import { cn } from '~/lib';
import { useIsChrome } from '~/lib/hooks/useIsChrome';
import { withZodTypeSafeProps } from '../../mdx-editor/components/withZodTypeSafeProps';

import type { HeightKeys } from '../playground-v2/mdx-playground-v2.const';
import { HeightMapping } from '../playground-v2/mdx-playground-v2.const';
import styles from './MdxPlayground.module.scss';
import type { PlaygroundModeType } from './MdxPlaygroundPropsSchema';
import { MdxPlaygroundPropsSchema } from './MdxPlaygroundPropsSchema';
import {
  SandPackPlaygroundPrettierButton,
  SandPackPlaygroundResetButton,
  SandpackPlaygroundResizeButton,
} from './SandPackPlaygroundButtons';
import { SandpackSecondPane } from './SandpackSecondPane';

const getLegacyMode = ({
  showConsole,
  onlyConsole,
  mode,
  template,
}: {
  showConsole?: boolean;
  onlyConsole?: boolean;
  mode?: PlaygroundModeType;
  template?: string;
}): PlaygroundModeType => {
  if (onlyConsole) {
    if (template?.includes('test')) {
      return 'both';
    }

    return 'console';
  }

  if (showConsole) {
    return 'both-tabs';
  }

  if (mode) {
    return mode;
  }

  return 'preview';
};

type PlaygroundType = z.infer<typeof MdxPlaygroundPropsSchema>;

const getHeight = (height?: HeightKeys, size?: PlaygroundType['size']) => {
  if (height) {
    return HeightMapping[height] ?? 500;
  }
  switch (size) {
    case 'small':
      return 300;
    case 'medium':
      return 600;
    case 'large':
      return 800;
    case 'extraSmall':
      return 200;
    default:
      return 600;
  }
};

const MdxPlayground = ({
  visibleFiles,
  activeFile,
  tailwind,
  dependencies,
  files,
  showConsole,
  size: baseSize,
  orientation,
  onlyConsole,
  showLineNumbers,
  title,
  defaultTab,
  showNavigator,
  mode: newMode,
  template,
  previewSize,
  hideEditor,
  height: heightKey,
  externals,
}: PlaygroundType) => {
  const codemirrorInstance = useRef<CodeMirrorRef>(null);
  const [fullScreen, setFullScreen] = useState(false);
  const isChrome = useIsChrome();
  const theme = useTheme();
  const isClient = useIsClient();
  const isDesktop = useMedia(tailwindMediaQuery.lg, false);
  const height = getHeight(heightKey, baseSize);

  const memorizedOptions = useMemo(() => {
    const externalResources = externals ?? [];
    if (tailwind) {
      externalResources.push('https://cdn.tailwindcss.com');
    }
    const obj = {
      visibleFiles: visibleFiles as never,
      activeFile: activeFile as never,
      externalResources: [...externalResources],
    };
    return obj;
  }, [activeFile, externals, tailwind, visibleFiles]);

  const memorizedCustomSetup = useMemo(() => {
    return {
      dependencies,
    };
  }, [dependencies]);

  useEffect(() => {
    if (!fullScreen) return;

    // I need to use querySelector to get the body because his style not fit with fullscreen
    const body = document.querySelector('body');
    if (!body) return;

    body.style.overflow = 'hidden';
    body.style.paddingLeft = '15px';

    return () => {
      body.style.overflow = 'unset';
      body.style.paddingLeft = 'unset';
    };
  }, [fullScreen]);

  if (!isClient)
    return (
      <SkeletonButton
        data-playground
        color="primary"
        style={{
          height,
          width: '100%',
        }}
      />
    );

  const fullScreenStyle = fullScreen ? { height: 'calc(100% - 22px)' } : undefined;

  const mode = getLegacyMode({ showConsole, onlyConsole, mode: newMode, template });

  return (
    <SandpackProvider
      options={memorizedOptions}
      files={files}
      template={template}
      customSetup={memorizedCustomSetup}
      data-jsx-element
      style={
        fullScreen
          ? {
              position: 'fixed',
              top: 0,
              overflow: 'hidden',
              left: 0,
              right: 0,
              bottom: 0,
              maxHeight: '100vh',
              zIndex: 9999,
              marginBottom: 0,
            }
          : undefined
      }
      className={cn(
        'jsx-element shadow-xl',
        fullScreen ? undefined : 'super-wrapper not-prose',
        styles.main
      )}
    >
      <DeleteFiles files={files} />
      <SandpackThemeProvider
        style={fullScreenStyle}
        theme={theme.isDark ? 'dark' : githubLight}
      >
        <div className={styles.header}>
          <Typo variant="large" as="p" className={cn('!m-0', styles.typography)}>
            {title}
          </Typo>
          {!isChrome && (
            <Badge color="orange" className="mx-2">
              Chrome only is supported
            </Badge>
          )}

          <div className="ml-auto flex items-center gap-2">
            <SandPackPlaygroundPrettierButton
              codemirrorInstance={codemirrorInstance}
            />

            <SandpackPlaygroundResizeButton
              enabled={fullScreen}
              onClick={() => setFullScreen((p) => !p)}
            />

            <SandPackPlaygroundResetButton />
          </div>
        </div>
        <SandpackLayout
          style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            minHeight: height,
            // @ts-ignore
            ['--sp-layout-height']: fullScreen ? '100%' : `${height}px`,
            ...fullScreenStyle,
          }}
          className={cn({
            'flex flex-col': orientation === 'vertical',
            'flex flex-row': orientation === 'horizontal',
          })}
        >
          {hideEditor ? (
            <div style={{ height: fullScreen ? '100%' : height, width: '100%' }}>
              <SandpackSecondPane
                isTest={template.includes('test')}
                defaultTab={defaultTab}
                showNavigator={showNavigator}
                mode={mode}
              />
            </div>
          ) : (
            <SplitPane
              defaultFirstRatio={1 - previewSize}
              height={fullScreen ? '100%' : height}
              direction={isDesktop ? 'horizontal' : 'vertical'}
            >
              <SandpackCodeEditor
                showLineNumbers={showLineNumbers}
                ref={codemirrorInstance}
                style={{ height: '100%' }}
              />

              <SandpackSecondPane
                isTest={template.includes('test')}
                defaultTab={defaultTab}
                showNavigator={showNavigator}
                mode={mode}
              />
            </SplitPane>
          )}
        </SandpackLayout>
      </SandpackThemeProvider>
    </SandpackProvider>
  );
};

export default withZodTypeSafeProps({
  Component: MdxPlayground,
  schema: MdxPlaygroundPropsSchema,
  name: 'Playground',
});

const DeleteFiles = ({ files }: { files: PlaygroundType['files'] }) => {
  const { sandpack } = useSandpack();

  useEffectOnce(() => {
    Object.entries(files).forEach(([fileName, file]) => {
      if (typeof file !== 'string' && file.deleted) {
        sandpack.deleteFile(fileName);
      }
    });
  });
  return null;
};
